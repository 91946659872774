import React from 'react'
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from "gatsby"
import { TiSocialFacebook, TiSocialLinkedin } from "react-icons/ti";
import parse from 'html-react-parser'
import { Container } from 'react-bootstrap'
import Logo from '../../../../assets/images/logo/simplr-logo.png'
import { Row, Col } from '../../../../components/ui/wrapper'
import Text from '../../../../components/ui/text'
import Anchor from '../../../../components/ui/anchor'
import Heading from '../../../../components/ui/heading'
import Social, { SocialLink } from '../../../../components/ui/social'
import {
    FooterWrap,
    FooterTop,
    FooterWidget,
    LogoWidget,
    TextWidget,
    FooterWidgetList,
    FooterBottom
} from './footer.style'

const Footer = ({ copyrightStyle, ...props }) => {
    const siteInfo = useStaticQuery(graphql`
        query FooterSiteQuery {
            site {
                siteMetadata {
                    copyright
                    contact {
                        phone
                        email
                        email2
                        email3
                        phone2
                        phone3
                        address
                        website
                    }
                    social {
                        facebook
                        twitter
                        instagram
                        linkedin
                    }
                }
            }
        }      
    `)
    const { phone, address, email2, email3, phone2, phone3} = siteInfo.site.siteMetadata.contact;
    const { copyright } = siteInfo.site.siteMetadata;
    const { facebook, linkedin } = siteInfo.site.siteMetadata.social;
    return (
        <FooterWrap {...props}>
            <FooterTop>
                <Container>
                    <Row>
                        <Col lg={6} sm={6}>
                            <FooterWidget responsive={{ medium: { mb: '31px' } }}>
                                <LogoWidget>
                                    <img src={Logo} alt="Logo" />
                                </LogoWidget>
                                <TextWidget>
                                    {address && <Text mb="10px">{address}</Text>}
                                    {phone && <Text mb="10px"><Anchor path={`tel:${phone}`} fontWeight="800" color="#333" hoverstyle="2">{phone}</Anchor></Text>}                                 
                                    <br></br>
                                    <Row>
                                        <Col lg={5} sm={6}>
                                            {email3 && <Text mb="10px"><Anchor path={`mailto:${email3}`} color="textColor" hoverstyle="2">{email3}</Anchor></Text>}
                                             {phone3 && <Text mb="10px"><Anchor path={`tel:${phone3}`} fontWeight="800" color="#333" hoverstyle="2">{phone3}</Anchor></Text>}
                                        </Col>                                    
                                    </Row>
                                    <br></br>  
                                    <Row>
                                        <Col lg={6} sm={6}>
                                            {email2 && <Text mb="10px"><Anchor path={`mailto:${email2}`} color="textColor" hoverstyle="2">{email2}</Anchor></Text>}
                                            {phone2 && <Text mb="10px"><Anchor path={`tel:${phone2}`} fontWeight="800" color="#333" hoverstyle="2">{phone2}</Anchor></Text>}
                                        </Col>
                                    </Row> 
                                </TextWidget>
                            </FooterWidget>
                        </Col>
                        <Col lg={3} md={4} sm={6}>
                            <FooterWidget responsive={{ medium: { mb: '31px' } }}>
                                <Heading as="h6" mt="-3px" mb="20px">Services</Heading>
                                <FooterWidgetList>
                                    <li><Anchor path="/services/services-it-outsourcing" color="textColor" hoverstyle="2">IT Outsourcing</Anchor></li>
                                    <li><Anchor path="/services/services-project-rescue" color="textColor" hoverstyle="2">Project Rescue</Anchor></li>
                                    <li><Anchor path="/services/services-system-integration" color="textColor" hoverstyle="2">System Integration</Anchor></li>
                                    <li><Anchor path="/services/services-cloud-solutions" color="textColor" hoverstyle="2">Cloud Solutions</Anchor></li>
                                    <li><Anchor path="/services/services-product-development" color="textColor" hoverstyle="2">Product Development</Anchor></li>
                                    <li><Anchor path="/services/services-support-maintenance" color="textColor" hoverstyle="2">Support & Maintenance</Anchor></li>
                                </FooterWidgetList>
                            </FooterWidget>
                        </Col>
                        <Col lg={2} md={4} sm={6}>
                            <FooterWidget responsive={{ medium: { mb: '27px' } }}>
                                <Heading as="h6" mt="-3px" mb="20px">Quick links</Heading>
                                <FooterWidgetList>
                                    <li><Anchor path="/portfolio/portfolio-banking-institutions" color="textColor" hoverstyle="2">Banking Institutions</Anchor></li>
                                    <li><Anchor path="/portfolio/portfolio-insurance-institutions" color="textColor" hoverstyle="2">Insurance Institutions</Anchor></li>
                                    <li><Anchor path="/portfolio/portfolio-workflow-automation" color="textColor" hoverstyle="2">Workflow Automations</Anchor></li>
                                    <li><Anchor path="/contact-us" color="textColor" hoverstyle="2">Contact Us</Anchor></li>
                                </FooterWidgetList>
                            </FooterWidget>
                        </Col>
                    </Row>
                </Container>
            </FooterTop>
            <FooterBottom>
                <Container>
                    <Row className="align-items-center">
                        <Col md={7} className="text-center text-md-left">
                            {copyright && <Text {...copyrightStyle}>&copy; {new Date().getFullYear()} {parse(copyright)}</Text>}
                        </Col>
                        <Col md={4} className="text-center text-md-right">
                            <Social space="8px" tooltip={true} shape="rounded" varient="outlined">
                                {facebook && (
                                    <SocialLink
                                        path={facebook}
                                        title="Facebook">
                                        <TiSocialFacebook />
                                    </SocialLink>
                                )}
                                {linkedin && (
                                    <SocialLink
                                        path={linkedin}
                                        title="Linkedin">
                                        <TiSocialLinkedin />
                                    </SocialLink>
                                )}
                            </Social>
                        </Col>
                    </Row>
                </Container>
            </FooterBottom>
        </FooterWrap>
    )
}

Footer.propTypes = {
    bgcolor: PropTypes.string,
    reveal: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

Footer.defaultProps = {
    bgcolor: '#F8F8F8',
    reveal: 'false',
    copyrightStyle: {
        responsive: {
            small: {
                pb: '15px'
            }
        }
    }
};

export default Footer
